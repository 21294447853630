import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import './css/home.school.css';
import './App.css';
import {isLoggedIn, isExpired } from './utils/AuthService';

const Home = Loadable({
  loader: () => import('./components/Home'),
  loading() {
    return ''
  }
});
const ForgotPassword = Loadable({
  loader: () => import('./components/ForgotPassword'),
  loading() {
    return ''
  }
});
const ResetPassword = Loadable({
  loader: () => import('./components/ResetPassword'),
  loading() {
    return ''
  }
});
const ChangePassword = Loadable({
  loader: () => import('./components/ChangePassword'),
  loading() {
    return ''
  }
});
const Students = Loadable({
  loader: () => import('./components/Students'),
  loading() {
    return ''
  }
});
const Profile = Loadable({
  loader: () => import('./components/Profile'),
  loading() {
    return ''
  }
});
const HomeSchool = Loadable({
  loader: () => import('./components/HomeSchool'),
  loading() {
    return ''
  }
});
const AbcData = Loadable({
  loader: () => import('./components/AbcData'),
  loading() {
    return ''
  }
});
const AbcEdit = Loadable({
  loader: () => import('./components/AbcEdit'),
  loading() {
    return ''
  }
});
const AbcList = Loadable({
  loader: () => import('./components/AbcList'),
  loading() {
    return ''
  }
});
const Report = Loadable({
  loader: () => import('./components/Report'),
  loading() {
    return ''
  }
});
const ProfileEdit = Loadable({
  loader: () => import('./components/ProfileEdit'),
  loading() {
    return ''
  }
});
const AddProfile = Loadable({
  loader: () => import('./components/AddProfile'),
  loading() {
    return ''
  }
});
const Logout = Loadable({
  loader: () => import('./components/Logout'),
  loading() {
    return ''
  }
});
const Failedauth = Loadable({
  loader: () => import('./components/Failedauth'),
  loading() {
    return ''
  }
});
const Subscriptions = Loadable({
  loader: () => import('./components/Subscriptions'),
  loading() {
    return ''
  }
});

const UserCards = Loadable({
  loader: () => import('./components/UserCards'),
  loading() {
    return ''
  }
});

const Subscribe = Loadable({
  loader: () => import('./components/Subscribe'),
  loading() {
    return ''
  }
});

const AbcOptions = Loadable({
  loader: () => import('./components/AbcOptions'),
  loading() {
    return ''
  }
});

const AddOption = Loadable({
  loader: () => import('./components/AddOption'),
  loading() {
    return ''
  }
});

const EditOption = Loadable({
  loader: () => import('./components/EditOption'),
  loading() {
    return ''
  }
});

class App extends Component {
  constructor(props) {
      super(props);
      
      this.state = {
        auth_message: 'Please sign in to continue..',
        menu_pages:'',
        role_id: 1
      }
  }

  componentWillMount () {
    if(isLoggedIn() === true){
      let userdata = JSON.parse(localStorage.getItem('user'));
      this.setState({
        role_id: userdata.role_id
      });
    }
  }

  render() {   
    
    return (
      <Router>
        <div>
           <Switch>
              <Route exact path='/' component={Home} />
              <Route 
                exact path="/forgot-password" render={() => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <Redirect 
                      to={{
                        pathname: '/students'
                      }} 
                    />
                  ) : (
                    <ForgotPassword />
                  )
                )} 
              />
              <Route 
                exact path="/reset-password/:token" render={(props) => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <Redirect 
                      to={{
                        pathname: '/students'
                      }} 
                    />
                  ) : (
                    <ResetPassword {...props} />
                  )
                )} 
              />
              <Route 
                exact path="/students" render={() => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <Students />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/abc-options" render={() => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <AbcOptions />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/subscriptions" render={() => (
                  (isLoggedIn() && (isExpired() === false) && this.state.role_id === 1) ? (
                    <Subscriptions />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/subscribe" render={() => (
                  (isLoggedIn() && (isExpired() === true)) ? (
                    <Subscribe />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/change-password" render={() => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <ChangePassword />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/profile/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <Profile {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/add-student" render={() => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id === 1)) ? (
                    <AddProfile />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/add-option" render={() => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id < 3)) ? (
                    <AddOption />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/edit-option/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id < 3)) ? (
                    <EditOption {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/profile-edit/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id < 3)) ? (
                    <ProfileEdit {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/reports/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <Report {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/home-school/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <HomeSchool {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/abc-data/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id < 3)) ? (
                    <AbcData {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/abc-edit/:id/:abc_id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false) && (this.state.role_id < 3)) ? (
                    <AbcEdit {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/abc-list/:id" render={(props) => (
                  (isLoggedIn() && (isExpired() === false)) ? (
                    <AbcList {...props} />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/cards" render={() => (
                  (isLoggedIn() && (this.state.role_id === 1)) ? (
                    <UserCards />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
              <Route 
                exact path="/logout" render={() => (
                  isLoggedIn() ? (
                    <Logout />
                  ) : (
                    <Failedauth />
                  )
                )} 
              />
           </Switch>
        </div>
     </Router>
    );
  }
}

export default App;
