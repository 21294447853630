
export function login(user) {
  localStorage.setItem('user',JSON.stringify(user));
  if(user.trial_notification !== undefined){
    localStorage.setItem('subs_reminder',user.trial_notification);
  }
}

export function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('subs_reminder');
  localStorage.clear();
  window.location.replace("/");
}

export function isLoggedIn() {
  if(localStorage.getItem('user') === null){
    return false;
  }
  return true;
}

export function isExpired() {
  if((localStorage.getItem('user') !== null) && (JSON.parse(localStorage.getItem('user'))['is_expired'] === 1)){
    return true;
  }
  return false;
}